<template>
    <div class="container">
        <!-- 头部 -->
        <Header :tab="1" />
        <!-- 身体 -->
        <div class="main">
            <div class="banner wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                <img src="../../assets/imgs/dinzhi111.png" class="banner_img" alt />
            </div>

            <!-- 主体 -->
            <div class="main_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                <!-- 头部 -->
                <div class="main_box_top">
                    <div class="main_box_top_l wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
                        :data-wow-offset="height_top">
                        <div class="main_box_top_l_img_box">
                            <img src="../../assets/imgs/a33.png" class="main_box_top_l_img" alt="">
                        </div>
                        <div>与国际技术同步，呈现高端完美效果，一体式包装，功能强大，延展领域开阔，</div>
                        <div> 沿用HTML5 + CSS3技术，响应式设计，多端访问，多语言等国际化高端设计标准及技术，</div>
                        <div>从项目策划、设计、开发，提供全程化、一体化服务模式。</div>
                    </div>
                    <div class="main_box_top_r wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
                        :data-wow-offset="height_top">
                        <div class="main_box_top_r_item" v-for="(item, index) in list" :key="index">
                            <div class="main_box_top_r_img_box">
                                <img :src="item.image" class="main_box_top_r_img" alt="">
                            </div>
                            <div class="main_box_top_r_item_title">{{ item.title }}</div>
                            <div class="main_box_top_r_item_txt">{{ item.summary }}</div>
                        </div>

                    </div>
                </div>
                <!-- 展示 -->
                <div class="main_box_center wow animate__slideInUp" data-wow-delay="0" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div class="main_box_center_l">
                        <div class="main_box_center_l_item wow animate__slideInUp" data-wow-delay="0.2s"
                            data-wow-iteration="1" :data-wow-offset="height_top" v-for="(item, index) in list3"
                            :key="index">
                            {{ item.title }}</div>

                    </div>
                    <div class="main_box_center_r wow animate__slideInUp" data-wow-delay="0.6s" data-wow-iteration="1"
                        :data-wow-offset="height_top">
                        <img src="../../assets/imgs/jianshe.png" class="main_box_center_r_img" alt />
                    </div>
                </div>
                <!-- 工具 -->
                <div class="main_box_center_1 wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div class="main_box_center_1_title wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
                        :data-wow-offset="height_top">为企业量身打造省钱、赚钱的工具</div>
                    <div class="main_box_center_1_txt wow animate__slideInUp" data-wow-delay="0.2s" data-wow-iteration="1"
                        :data-wow-offset="height_top">我们交付的不止一个简单的网站</div>
                    <div class="main_box_center_1_box">
                        <div class="main_box_center_1_box_item wow animate__slideInUp" data-wow-delay="0.2s"
                            data-wow-iteration="1" :data-wow-offset="height_top" v-for="(item, index) in list1"
                            :key="index">
                            <div class="main_box_center_1_box_img_box">
                                <img :src="item.image" class="main_box_center_1_box_img" alt />
                            </div>
                            <div class="main_box_center_1_box_item_title">{{ item.title }}</div>
                            <div class="main_box_center_1_box_item_txt">{{ item.summary }}</div>
                        </div>

                    </div>
                </div>
                <!-- 推荐 -->
                <div class="title_box wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top">
                    <div>推荐案例</div>
                    <div class="title_box_text">
                        眼光高度决定品牌厚度！
                    </div>
                </div>
                <div class="main_box_center_btn wow animate__slideInUp" data-wow-iteration="1"
                    :data-wow-offset="height_top">
                    <div @click="toDetails(item.id)" v-for="(item, index) in list2" :key="index"
                        class="main_box_center_btn_box wow animate__slideInUp" :data-wow-delay="index / 10 + 0.2 + 's'"
                        data-wow-iteration="1" :data-wow-offset="height_top">
                        <div class="main_box_center_btn_item">
                            <div class="main_box_center_btn_item_img_box" ref="btnItem"
                                :style="{ height: tempHeight + 'px' }">
                                <img :src="item.list_image1" class="main_box_center_btn_item_img" alt />
                            </div>
                            <div class="main_box_center_btn_item_box">
                                <div class="main_box_center_btn_item_box_title">{{ item.title }}</div>
                                <div class="main_box_center_btn_item_box_txt">{{ item.summary }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 底部 -->
        <Footer class="wow animate__slideInUp" data-wow-iteration="1" :data-wow-offset="height_top" />
    </div>
</template>
  
<script>
import Footer from "@/components/Footer/Footer";
import Header from "@/components/Header/Header";
import storage from "@/utils/storage";
import { articlelist20, articlellist21, projectsrecommends, articlelist4 } from "@/api/axios/axios.api";
import { WOW } from "wowjs";
export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            height_top: storage.height_top,
            tempHeight: '',
            timer: null,
            list: [],
            list1: [],
            list2: [],
            list3: [],
        };
    },
    methods: {
        // 案例详情
        toDetails(id) {
            this.$router.push({
                name: 'sucsessdetails',
                query: {
                    id: id
                }
            })
        },
        // 设置高度
        height() {
            //进入页面元素默认宽度
            this.timer = setInterval(() => {
                if (!this.$refs.btnItem) {
                    this.tempHeight = this.$refs.btnItem[0].offsetWidth
                    clearInterval(this.timer)
                    this.timer = null
                } else {
                    clearInterval(this.timer)
                    this.timer = null
                }
            }, 1000);
            //根据屏幕缩放自动获取页面宽度
            window.onresize = () => {
                return (() => {
                    //窗口缩放自动获取元素宽度
                    if (this.$refs.btnItem) {
                        this.tempHeight = this.$refs.btnItem[0].offsetWidth //宽度
                    }
                })()
            }
        },

        // 获取数据
        async getData() {
            // 服务类容
            let res = await articlelist20()
            this.list = res.data.list
            // 竞争优势
            let rec = await articlellist21()
            this.list1 = rec.data.list
            // 案例推荐
            let ret = await projectsrecommends(1)
            this.list2 = ret.data.list
            if (ret.data.list.length) {
                this.height();
            }
            // 底部数据
            let rex = await articlelist4()
            this.list3 = rex.data.list[0].list
        },

        // 缩放比例
        resize() {
            // 系统整体缩放
            let cliWidth = document.documentElement.clientWidth || document.body.clientWidth
            let cliHeight = document.documentElement.clientHeight || document.body.clientHeight
            let contW = 1920
            let contH = 1080
            let w = cliWidth / contW
            let h = cliHeight / contH
            let appDom = document.querySelector('.container')
            appDom.style.transform = 'scale(' + w + ',' + h + ')'
            appDom.style.transformOrigin = 'top left'
            appDom.style.width = contW + 'px'
            appDom.style.height = contH + 'px'
        }
    },
    created() { },
    mounted() {
        this.getData()
        this.$nextTick(() => {
            // this.resize()
            // window.onresize = function () {
            //     this.resize()
            // }.bind(this)
            // this.footerData()
            let wow = new WOW({
                live: true,
            });
            wow.init();
        });
    },
    watch: {},
    beforeDestroy() {
        clearInterval(this.timer);
    },
};
</script>
  
<style lang="less" scoped>
@media screen and (min-width : 1400px) {
    .banner {
        color: #fff;
        // height: 530px;

        .banner_img {
            // position: absolute;
            // height: 530px;
            width: 100%;
        }
    }

    // 身体
    .main_box {
        // padding: 0 10%;
        // padding-bottom: 42px;
        background-color: #fff;

        // 头部
        .main_box_top {
            padding: 38px 10%;
            box-sizing: border-box;

            .main_box_top_l {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                line-height: 34px;
                color: #333333;
                transition: all 0.5s;
                padding: 20px;

                .main_box_top_l_img_box {
                    width: 240px;
                    height: 124px;
                    margin-bottom: 40px;

                    .main_box_top_l_img {
                        width: 240px;
                        height: 124px;
                    }
                }

                .main_box_top_l_txt {
                    font-size: 20px;
                    color: #333333;
                    margin-bottom: 26px;
                }

                .main_box_top_l_title {
                    font-size: 40px;
                    color: #333333;
                    font-weight: bold;
                }
            }

            .main_box_top_l:hover {
                // border-radius: 50%;
                transform: scale(1.2);
                transition: all 0.5s;
                // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }

            .main_box_top_r {
                margin-top: 100px;
                align-items: center;
                font-size: 14px;
                line-height: 34px;
                color: #666;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                .main_box_top_r_item {
                    width: calc(100% / 4);
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    line-height: 34px;
                    color: #666666;
                    font-size: 14px;
                    transition: all 0.5s;

                    .main_box_top_r_img_box {
                        width: 66px;
                        height: 66px;

                        .main_box_top_r_img {
                            width: 66px;
                            height: 66px;
                        }
                    }

                    .main_box_top_r_item_title {
                        font-size: 20px;
                        color: #333333;
                        margin: 30px 0;
                        font-weight: bold;
                    }

                    .main_box_top_r_item_txt {
                        max-width: 160px;
                    }
                }

                .main_box_top_r_item:hover {
                    // border-radius: 50%;
                    transform: scale(1.2);
                    transition: all 0.5s;
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }
        }

        // 展示
        .main_box_center {
            padding: 0 10%;
            min-height: 751px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: url("../../assets/imgs/jianshe1.png") no-repeat;
            background-size: 100% 100%;

            .main_box_center_l {
                width: 46%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .main_box_center_l_item {
                    // width: 323px;
                    width: 45%;
                    height: 96px;
                    text-align: center;
                    line-height: 96px;
                    background: #006DFE;
                    border-radius: 10px;
                    margin-right: 5%;
                    margin-bottom: 3%;
                    color: #FFFFFF;
                    font-size: 26px;
                    transition: all 0.5s;
                }

                .main_box_center_l_item:hover {
                    background-color: #fff;
                    color: #333333;
                    transition: all 0.5s;
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }

            .main_box_center_r {
                width: 46%;
                padding-left: 8%;

                .main_box_center_r_img {
                    width: 100%;
                    transition: all 0.5s;
                }
            }

            .main_box_center_r:hover .main_box_center_r_img {
                transform: scale(1.2);
                transition: all 0.5s;
                // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }

        }

        // 工具
        .main_box_center_1 {
            padding: 80px 10%;

            .main_box_center_1_title {
                color: #333333;
                font-size: 26px;
                text-align: center;
            }

            .main_box_center_1_txt {
                text-align: center;
                font-size: 16px;
                margin: 40px 0;
                color: #999999;
            }

            .main_box_center_1_box {
                display: flex;
                align-items: center;
                justify-content: center;

                .main_box_center_1_box_item {
                    width: calc(100% / 5);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px;
                    transition: all 0.5s;
                    text-align: center;

                    .main_box_center_1_box_img_box {
                        width: 113px;
                        height: 122px;

                        .main_box_center_1_box_img {
                            width: 113px;
                            height: 122px;
                        }
                    }

                    .main_box_center_1_box_item_title {
                        padding: 35px 0;
                        color: #333333;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    .main_box_center_1_box_item_txt {
                        color: #666666;
                        font-size: 14px;
                        line-height: 34px;
                        max-width: 200px;
                    }
                }

                .main_box_center_1_box_item:hover {
                    // border-radius: 50%;
                    transition: all 0.5s;
                    transform: scale(1.2);
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }
        }

        // 推荐
        .title_box {
            padding: 50px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #333333;
            font-size: 28px;
            background-color: #f5f5f5;

            .title_box_text {
                margin-top: 21px;
                font-weight: normal;
                color: #666666;
                font-size: 18px;
            }
        }

        .main_box_center_btn {
            padding: 0 10%;
            padding-bottom: 66px;
            display: flex;
            // justify-content: space-between;
            background-color: #f5f5f5;
            align-items: center;

            .main_box_center_btn_box {
                width: calc(100% / 3);
                display: flex;
                align-items: center;
                justify-content: center;

                .main_box_center_btn_item {
                    // width: 407px;
                    width: 100%;
                    // height: 514px;
                    background: #FFFFFF;
                    padding: 2%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    transition: all 0.5s;

                    .main_box_center_btn_item_img_box {
                        width: 100%;
                        // height: 378px;
                        overflow: hidden;

                        .main_box_center_btn_item_img {
                            width: 100%;
                            height: 100%;
                            transition: all 0.5s;
                        }

                    }

                    .main_box_center_btn_item_box {
                        flex: 1;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 8% 0;

                        .main_box_center_btn_item_box_title {
                            color: #333333;
                            font-size: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            margin-bottom: 5%;
                        }

                        .main_box_center_btn_item_box_txt {
                            color: #999999;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }
                    }

                }

                .main_box_center_btn_item:hover {
                    transition: all 0.5s;
                    box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }

                .main_box_center_btn_item:hover .main_box_center_btn_item_img {
                    transform: scale(1.5);
                    transition: all 0.5s;
                }
            }

            .main_box_center_btn_box:nth-child(2) {
                margin: 0 5%;
            }
        }
    }
}

@media screen and (max-width : 1399px) {
    .banner {
        min-width: 1300px;
        color: #fff;
        // height: 530px;

        .banner_img {
            // position: absolute;
            // height: 530px;
            width: 100%;
        }
    }

    // 身体
    .main_box {
        min-width: 1300px;
        // padding: 0 10%;
        // padding-bottom: 42px;
        background-color: #fff;

        // 头部
        .main_box_top {
            padding: 38px 20px;
            box-sizing: border-box;

            .main_box_top_l {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                line-height: 34px;
                color: #333333;
                transition: all 0.5s;
                padding: 20px;

                .main_box_top_l_img_box {
                    width: 240px;
                    height: 124px;
                    margin-bottom: 40px;

                    .main_box_top_l_img {
                        width: 240px;
                        height: 124px;
                    }
                }

                .main_box_top_l_txt {
                    font-size: 20px;
                    color: #333333;
                    margin-bottom: 26px;
                }

                .main_box_top_l_title {
                    font-size: 40px;
                    color: #333333;
                    font-weight: bold;
                }
            }

            .main_box_top_l:hover {
                // border-radius: 50%;
                transform: scale(1.2);
                transition: all 0.5s;
                // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }

            .main_box_top_r {
                margin-top: 100px;
                align-items: center;
                font-size: 14px;
                line-height: 34px;
                color: #666;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;

                .main_box_top_r_item {
                    width: calc(100% / 4);
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    line-height: 34px;
                    color: #666666;
                    font-size: 14px;
                    transition: all 0.5s;

                    .main_box_top_r_img_box {
                        width: 66px;
                        height: 66px;

                        .main_box_top_r_img {
                            width: 66px;
                            height: 66px;
                        }
                    }

                    .main_box_top_r_item_title {
                        font-size: 20px;
                        color: #333333;
                        margin: 30px 0;
                        font-weight: bold;
                    }

                    .main_box_top_r_item_txt {
                        max-width: 160px;
                    }
                }

                .main_box_top_r_item:hover {
                    // border-radius: 50%;
                    transform: scale(1.2);
                    transition: all 0.5s;
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }
        }

        // 展示
        .main_box_center {
            padding: 0 20px;
            min-height: 751px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: url("../../assets/imgs/jianshe1.png") no-repeat;
            background-size: 100% 100%;

            .main_box_center_l {
                width: 46%;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;

                .main_box_center_l_item {
                    // width: 323px;
                    width: 45%;
                    height: 96px;
                    text-align: center;
                    line-height: 96px;
                    background: #006DFE;
                    border-radius: 10px;
                    margin-right: 5%;
                    margin-bottom: 3%;
                    color: #FFFFFF;
                    font-size: 26px;
                    transition: all 0.5s;
                }

                .main_box_center_l_item:hover {
                    background-color: #fff;
                    color: #333333;
                    transition: all 0.5s;
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }

            .main_box_center_r {
                width: 46%;
                padding-left: 8%;

                .main_box_center_r_img {
                    width: 100%;
                    transition: all 0.5s;
                }
            }

            .main_box_center_r:hover .main_box_center_r_img {
                // transform: scale(1.2);
                transition: all 0.5s;
                // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
            }

        }

        // 工具
        .main_box_center_1 {
            padding: 80px 20px;

            .main_box_center_1_title {
                color: #333333;
                font-size: 26px;
                text-align: center;
            }

            .main_box_center_1_txt {
                text-align: center;
                font-size: 16px;
                margin: 40px 0;
                color: #999999;
            }

            .main_box_center_1_box {
                display: flex;
                align-items: center;
                justify-content: center;

                .main_box_center_1_box_item {
                    width: calc(100% / 5);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: space-between;
                    padding: 20px;
                    transition: all 0.5s;
                    text-align: center;

                    .main_box_center_1_box_img_box {
                        width: 113px;
                        height: 122px;

                        .main_box_center_1_box_img {
                            width: 113px;
                            height: 122px;
                        }
                    }

                    .main_box_center_1_box_item_title {
                        padding: 35px 0;
                        color: #333333;
                        font-size: 20px;
                        font-weight: bold;
                    }

                    .main_box_center_1_box_item_txt {
                        color: #666666;
                        font-size: 14px;
                        line-height: 34px;
                        max-width: 200px;
                    }
                }

                .main_box_center_1_box_item:hover {
                    // border-radius: 50%;
                    transition: all 0.5s;
                    transform: scale(1.2);
                    // box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }
            }
        }

        // 推荐
        .title_box {
            padding: 50px 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            color: #333333;
            font-size: 28px;
            background-color: #f5f5f5;

            .title_box_text {
                margin-top: 21px;
                font-weight: normal;
                color: #666666;
                font-size: 18px;
            }
        }

        .main_box_center_btn {
            padding: 0 20px;
            padding-bottom: 66px;
            display: flex;
            // justify-content: space-between;
            background-color: #f5f5f5;
            align-items: center;

            .main_box_center_btn_box {
                width: calc(100% / 3);
                display: flex;
                align-items: center;
                justify-content: center;

                .main_box_center_btn_item {
                    // width: 407px;
                    width: 100%;
                    // height: 514px;
                    background: #FFFFFF;
                    padding: 2%;
                    box-sizing: border-box;
                    display: flex;
                    flex-direction: column;
                    cursor: pointer;
                    transition: all 0.5s;

                    .main_box_center_btn_item_img_box {
                        width: 100%;
                        // height: 378px;
                        overflow: hidden;

                        .main_box_center_btn_item_img {
                            width: 100%;
                            height: 100%;
                            transition: all 0.5s;
                        }

                    }

                    .main_box_center_btn_item_box {
                        flex: 1;
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        padding: 8% 0;

                        .main_box_center_btn_item_box_title {
                            color: #333333;
                            font-size: 20px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                            margin-bottom: 5%;
                        }

                        .main_box_center_btn_item_box_txt {
                            color: #999999;
                            font-size: 14px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 1;
                        }
                    }

                }

                .main_box_center_btn_item:hover {
                    transition: all 0.5s;
                    box-shadow: 0 0 20px rgba(153, 153, 153, 1);
                }

                .main_box_center_btn_item:hover .main_box_center_btn_item_img {
                    transform: scale(1.5);
                    transition: all 0.5s;
                }
            }

            .main_box_center_btn_box:nth-child(2) {
                margin: 0 1%;
            }
        }
    }
}
</style>
  